import { api } from '@/api'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { useProgrammatic } from '@oruga-ui/oruga-next'

import Home from '../views/Home.vue'
import { Ref, ref } from 'vue'
import { AxiosError } from 'axios'
import { printAxiosError } from '@/helpers/template.helper'

const { oruga } = useProgrammatic()

export const breadCrumbStack: Ref<Array<string>> = ref([])

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: () => {
      breadCrumbStack.value = []
    }
  },
  {
    path: '/template/download',
    name: 'Download Template',
    component: () => import('../views/TemplateDownload.vue'),
    beforeEnter: () => {
      breadCrumbStack.value = ['Download Template']
    }
  },
  {
    path: '/template/secure-link',
    name: 'Generate Secure Upload Link',
    component: () => import('../views/SecureLink.vue'),
    beforeEnter: () => {
      breadCrumbStack.value = ['Generate Secure Upload Link']
    }
  },
  {
    path: '/template/secure-link-sent/:email?',
    name: 'Secure Upload Link Generated',
    component: () => import('../views/SecureLinkSent.vue'),
    props: true,
    beforeEnter: (to, from) => {
      // check that redirect is from app
      // avoid fake/scam redirect
      if (from.name === 'Generate Secure Upload Link') {
        breadCrumbStack.value = ['Generate Secure Upload Link', 'Secure Upload Link Generated']

        return true
      } else {
        router.push('/')
      }
    }
  },
  {
    path: '/template/upload/:token?',
    name: 'Upload Template Landing',
    component: () => import('../views/TemplateUploadLanding.vue'),
    meta: {
      requireToken: true,
      possibleQueryParams: ['email']
    }
  },
  {
    path: '/template/upload',
    name: 'Upload Template',
    component: () => import('../views/GenericUploadForm.vue'),
    meta: {
      requireToken: true,
      possibleQueryParams: ['email']
    },
    children: [
      {
        path: 'incidental/:token?',
        name: 'Incidental Occurrences Data Upload',
        component: () => import('../components/Forms/IncidentalForm.vue')
      },
      {
        path: 'systematic/:token?',
        name: 'Systematic Survey Data Upload',
        component: () => import('../components/Forms/SystematicSurveyForm.vue')
      }
    ]
  },
  {
    path: '/template/upload/summary/:submissionID?',
    name: 'Submission Status',
    component: () => import('../views/SubmissionSummary.vue'),
    beforeEnter: () => {
      breadCrumbStack.value = ['Submission Status']
    }
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('../views/NotFound.vue'),
    beforeEnter: () => {
      breadCrumbStack.value = ['404']
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  // Query params forwarding
  if (Object.keys(from.query).length > 0) {
    const forwardQueryParams: string[] = [] // Query params to forward to the next route (in the URL)

    Object.keys(from.query).forEach((key) => {
      if (
        to.matched.some(
          // Check if some current query param is used by the next route
          (m) => m.meta.possibleQueryParams && (m.meta.possibleQueryParams as Array<string>).includes(key)
        )
      ) {
        forwardQueryParams.push(key) // Add to the list of query params to forward (in the URL)
        to.query[key] = from.query[key] // Add to the query params of the next route (in the Route location)
      }
    })

    if (forwardQueryParams.length) {
      to.fullPath = to.path + `?${forwardQueryParams.map((k) => `${k}=${from.query[k]}`).join('&')}` // Add the query params to the URL (to handle page refresh)
    }
  }

  // Token handling
  if (to.matched.some((m) => m.meta.requireToken) && !to.params.accessToken) {
    // If the route or a parent route requires a token
    const token = to.params.token as string
    try {
      const refresh = await api.auth.refreshToken(token)
      to.params.accessToken = refresh.data.access

      breadCrumbStack.value = to.name ? [to.name as string] : []
      return true
    } catch (e) {
      if (!(e instanceof AxiosError)) return

      let errMsg = null

      if (typeof e.response?.data.error !== 'string') return printAxiosError(e)

      if (e.response.data.error.includes('Refresh Token has expired')) {
        errMsg = 'Token expired'
      } else if (e.response.data.error.includes('Invalid Refresh Token')) {
        errMsg = 'Invalid Token'
      }

      if (!errMsg) return printAxiosError(e)

      oruga.notification.open({
        component: {
          props: [],
          template: `
          <div class="columns is-mobile">
            <div class="column">
            An error occurred: <b>${errMsg}</b>. Please request another <a @click="$emit('close')" href="${
              router.resolve({
                name: 'Generate Secure Upload Link'
              }).href
            }">Secure Upload Link</a> or contact support.
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column has-text-right">
              <o-button type="button" variant="primary" inverted @click="$emit('close')">OK</o-button>
            </div>
          </div>
          `
        },
        variant: 'danger',
        position: 'bottom',
        indefinite: true,
        closable: true
      })

      router.push('/')
    }
  }
  return true
})

export default router
