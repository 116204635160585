<template>
  <div class="container" @mouseenter="stopTimer()" @mouseleave="startTimer()">
    <div class="column is-mobile">
      <h2 class="title dawe-h2 mb-1">{{ clientError ? "Request" : "Server" }} Error</h2>
      <h3 class="title dawe-h6 mb-1">
        {{ props.axiosError.message }} ({{ statusMessage}})
      </h3>
    </div>

    <div v-if="error !== undefined || details" class="column is-mobile">
      <h3 class="title dawe-h3 mb-0">Server responded with:</h3>

      <div v-if="error !== undefined" class="pl-1 mb-3">
        <p>{{ error }}</p>
      </div>

      <ul v-if="details" class="list mb-3 pl-4">
        <li v-for="detail in details" :key="detail.id">
          {{ detail.type }}: {{ detail.msg }} {{ detail.loc?.length ? `at ${detail.loc.join('.')}` : '' }}
        </li>
        <li class="nodisc" v-if="detailsShortenedBy">
          ... and {{ detailsLength - details.length }} more
        </li>
      </ul>
    </div>

    <div v-else-if="props.axiosError.code === 'ERR_NETWORK'">
      <h3 class="title dawe-h4 mb-0">Please check your internet connection</h3>
    </div>

    <p class="mt-2 mb-2">Request sent on <b>{{ requestTime }}</b> and took <b>{{ duration }}ms</b></p>

    <div class="columns is-mobile mb-0">
      <div class="column annotation">
        <p>
          If you think there is a problem, you can dump the error
        </p>
        <span>
          and send it to
          <a :href="`mailto:${ENV.VUE_APP_SUPPORT_EMAIL_ADDRESS.value}`" target="_blank" class="is-underlined">
            {{ ENV.VUE_APP_SUPPORT_EMAIL_ADDRESS.value }}
            <o-icon pack="fas" icon="chevron-right" size="small"></o-icon>
          </a>
        </span>
      </div>
      <div class="column has-text-right is-narrow">
        <o-button type="button" variant="primary" inverted @click="dumpError" class="mr-4">Dump</o-button>
        <o-button type="button" variant="primary" inverted @click="$emit('close')">OK</o-button>
      </div>
    </div>

  </div>
</template>

<script setup lang="ts">
import router from '@/router'
import { AxiosError } from 'axios'
import { onMounted, ref } from 'vue'
import { ENV } from '@/constants'

const statusMessages: { [code: number]: string } = {
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Request Content Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  418: "I'm a teapot",
  420: 'Enhance Your Calm',
  422: 'Unprocessable Content',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  444: 'No Response',
  451: 'Unavailable For Legal Reasons',
  499: 'Client Closed Request',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  509: 'Bandwidth Limit Exceeded',
  510: 'Not Extended',
  511: 'Network Authentication Required',
  598: 'Network read timeout error',
  599: 'Network connect timeout error'
}

const props = defineProps<{
  axiosError: AxiosError
}>()

const emit = defineEmits(['close'])

// Timer to close the notification after a certain time
let timer: null | NodeJS.Timeout = null

// Stop the timer when the user hovers over the notification
const stopTimer = () => {
  if (timer) {
    clearTimeout(timer)
    timer = null
  }
}

// Start the timer to close the notification (Called on mounted and when the user leaves the notification)
const startTimer = (timeout: number = 5000) => {
  if (timer) clearTimeout(timer)

  timer = setTimeout(() => {
    emit('close')
  }, timeout)
}

const metadata = ((props.axiosError.config || {}) as any).metadata

let startDate = new Date(+metadata.startTime)
const offset = startDate.getTimezoneOffset()
startDate = new Date(startDate.getTime() - offset * 60000)
const requestTime = startDate.toISOString().split('.')[0].replace('T', ' ') // Format YYYY-MM-DD HH:MM:SS

/**
 * duration: number
 *  The duration of the request in milliseconds
 *
 * clientError: boolean
 *   True if the error is a client error (status code < 500)
 *   We assert that this component is only used for errors (4XX/5XX status codes)
 *
 * statusMessage: string
 *  The message corresponding to the status code of the error
 */
const duration = metadata.duration
const clientError = props.axiosError.response?.status || 0 < 500
const statusMessage = statusMessages[props.axiosError.response?.status || -1] || 'Unknown'

/**
 * MAX_DETAILS_LINES: const number
 *   Hard coded maximum number of lines to show in the details (including the "and X more" line)
 *
 * detailsLength: number
 *   The length of the detail array, before shortening. undefined if no detail
 *
 * details: Array<{type: string, msg: string, loc: string[]}>
 *   Shortened array of detail objects, each with a type, msg, and loc (location) array
 *
 * detailsShortenedBy: number
 *   The number of details that were not shown in the details array
 *
 * error: string
 *  The error message, if the response data is a string
 */
const MAX_DETAILS_LINES = 4
const detailsLength = ref<number>(0)
const details = ref<Array<{ type: string; msg: string; loc: string[]; id: any }> | undefined>()
const detailsShortenedBy = ref<number>()
const error = ref<string>()

/**
 * Will populate the error and/or details variables based on the response data
 * The error variable is supposed to be a string, and the details variable an array of objects describing positions and type of multiple errors
 * @param data The response data from the axios error
 */
const processErrors = (data: any) => {
  if (!data) return

  if (typeof data === 'string') {
    error.value = data
    return
  }

  if (data.detail instanceof Array && data.detail.length) {
    detailsLength.value = data.detail.length
    const shortenDetails = data.detail.slice(
      0,
      MAX_DETAILS_LINES < detailsLength.value ? MAX_DETAILS_LINES - 1 : detailsLength.value
    )
    details.value = shortenDetails
    detailsShortenedBy.value = detailsLength.value - shortenDetails.length
    return
  }

  if (typeof data.detail === 'string') {
    error.value = data.detail
  }

  if (data.error) {
    error.value = data.error
  } else if (data.message) {
    error.value = data.message
  }
}

/**
 * Recursive subfunction, check the real one for infos
 * @param obj object to censor
 * @param censoring censor word ('[CENSORED]', '***', 'XXX', etc.)
 * @param sensitiveFields Array of sensitive fields to censor (e.g. ['root.config.headers.Authorization'])
 * @param sensitiveValues Array of sensitive values to censor (e.g. [accessToken])
 * @param prefix initial prefix for the object (default: 'root')
 */
const _censor = (obj: any, censoring: string, sensitiveFields: string[], sensitiveValues: string[], prefix: string) => {
  if (sensitiveFields.includes(prefix)) {
    return censoring // The field is sensitive, no need to go further, just censor it
  }

  if (typeof obj === 'object') {
    const cpy: any = {}
    for (const key in obj) {
      cpy[key] = _censor(obj[key], censoring, sensitiveFields, sensitiveValues, `${prefix}.${key}`)
    }
    return cpy
  }

  if (typeof obj === 'string') {
    for (const value of sensitiveValues) {
      obj = obj.replace(value, censoring) // Using replace to also handle substrings & multiple occurrences
    }
  }

  return obj
}

/**
 * Recursively remove undefined, empty objects, and functions from an object (perfect for exporting)
 * @param obj object to clean
 */
const objectCleanup = (obj: any) => {
  if (typeof obj !== 'object') return obj

  for (const key in obj) {
    if (typeof obj[key] === 'object' && Object.keys(obj[key]).length > 0) {
      obj[key] = objectCleanup(obj[key]) // Non empty object ? recurse !
    }
    // Remove fields AFTER the recursion, because recursion may create empty objects
    if (
      // Remove fields that are either undefined, empty objects or functions
      obj[key] === undefined || // Remove only undefined, nulls can be useful
      typeof obj[key] === 'function' || // If the function was a getter, the copy from _censor would have make it a value
      (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
    ) {
      delete obj[key]
    }
  }

  return obj
}

/**
 * Will censor the object based on the sensitiveFields and sensitiveValues
 * @param obj object to censor
 */
const censor = (obj: any) => {
  const sensitiveFields: string[] = [
    // These fields ain't sensitive, just long and contain duplicated data
    'root.response.request',
    'root.request.response',
    'root.request.responseText'
  ]
  const accessToken = router.currentRoute.value.params.accessToken.toString()
  const sensitiveValues: Array<string> = [accessToken]

  const censored = _censor({ ...obj }, '[REMOVED]', sensitiveFields, sensitiveValues, 'root')

  return objectCleanup(censored)
}

/**
 * Dump the error to a JSON file, and download it
 */
const dumpError = () => {
  const censoredJSON = JSON.stringify(censor(props.axiosError), null, 2)
  const downloaderEl = document.createElement('a')
  downloaderEl.href = `data:text/json;charset=utf-8,${encodeURIComponent(censoredJSON)}`
  downloaderEl.download = `error${metadata.startTime}.json`
  downloaderEl.click()
  downloaderEl.remove()
}

onMounted(() => {
  startTimer(10000)
  processErrors(props.axiosError.response?.data)
})
</script>

<style scoped lang="scss">

.annotation{
  opacity: 0.7;
  font-size: 0.86rem;
  line-height: 1.2rem;
}

.list li {
  list-style-type: disc;
  text-wrap: nowrap;
  text-overflow: ellipsis
}

.list li.nodisc {
  list-style-type: none;
}

</style>
